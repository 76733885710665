@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap" rel="stylesheet');
@font-face {
  font-family: GT Alpina;
  src: url("./fonts/GT Alpina/gt alpina ttf/GTAlpina-Rg.ttf");
}
/* brand colors and other variables*/
:root {
  --primary-blue: #83B9F7;
  --primary-yellow: #E8E200;
  --primary-lavender: #DCB8F6;
  --primary-orange: #FF7700;
  --primary-pink: #FF80B8;

  --secondary-blue-light: #2A85F4;
  --secondary-blue-med: #265992;
  --secondary-blue-dark: #1A3251;

  --secondary-green-light: #8BD392;
  --secondary-green-med: #269375;
  --secondary-green-dark: #004C4F;
  
  --secondary-purple-light: #EEE3FF;
  --secondary-purple-med: #9A95E1;
  --secondary-purple-dark: #5C50BD;

  --secondary-yellow-light: #F3E6BB; /*HEX ON BRAND GUIDELINES IS INCORRECT AND PRODUCES A LIGHT BLUE COLOR (TODO)*/
  --secondary-yellow-med: #FFCE45;
  --secondary-yellow-dark: #FF9E00;

  --secondary-pink-light: #FFE6FF;
  --secondary-pink-med: #FFB2E5;
  --secondary-pink-dark: #EA5FD8;

  --logo-height: 12vw;
  --min-logo-height-inches: 1;
}

/* base styles */
* {
  position: relative;
  margin: 0px;
  padding: 0px;
  font-family: 'GT Alpina';
  font-size: max(min(36px, 3vw), 16px);
  color: #333;
}

.App {
  position: relative;
}

h1 {
  font-size: 1.2360679775rem;
}

h2 {
  font-size: 0.97173654351rem;
}

h3 {
  font-size: 0.7639320225rem;
}

h4 {
  font-size: 0.600566212rem;
}

h5 {
  font-size: 0.472135955rem;
}

h6 {
  font-size: 0.37117033151rem;
}

div {
  font-size: 0.75rem;
}

p {
  font-size: 0.75rem;
}

a {
  font-size: 0.75rem;
  color: #1A3251;
}

b,em {
  font-size: 0.75rem;
}

i{
  font-size: 0.75rem;
}

li{
  font-size: 0.75rem;
  margin-left: 5.57280900008vw;
}

sup{
  font-size: 0.4rem;
}

table{
  font-size: 0.75rem;
  align-content: start;
}

th{
  font-size: 0.75rem;
}

tr{
  font-size: 0.75rem;
}

td{
  font-size: 0.75rem;
  padding-bottom: .5em;
  border-top-style: solid;
  border-width: 1px;
}

table.comparison th, table.comparison td {
  width: 50%;
}

table.comparison td {
  padding: 20px;
}

.test-mark {
  background-color: #83B9F7;
}

/* 
Since logo max height is: 1 inch
And logo display height is: 12vw
The minimum screen width to display the logo is: 1 * (100 / 12) inches.
This is 8.333 inches.
*/
@media (min-width: 8.333in) {
  .icon {
    display: none;
  }
  .logo {
    display: block;
  }
}

@media (max-width: 8.333in) {
  .icon {
    display: block;
  }
  .logo {
    display: none;
  }
}

.logo {
  height: var(--logo-height);
  padding: 20%; /*brand guidelines for spacing around logo*/
  box-sizing: border-box;
}

@media (max-width: 1458px) {
  .nav-title {
    grid-column-start: 1;
    grid-column-end: 23;
  }
}

.icon {
  height: max(min(1in, 10vw), 64px);
  padding: 20%;
  box-sizing: border-box;
}

@media (max-height: 799px) {
  .icon {
    margin-top: 1vw;
    margin-bottom: 1vw;
  }
}

.home {
  padding: 10px;
}

.flex-wrap {
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: space-around;
}

.navbar {
  position: relative;
  align-items: center;
  justify-content:space-around;
  max-width: auto;
  border-bottom: 1px solid #f2f2f2; 
  height: auto;
}

@media (min-width: 1459px) {
  .navbar {
    display: flex;
  }
}
@media (max-width: 1458px) {
  .navbar {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
  }
}

.nav-links {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nav-header {
  position: relative;
  padding: 1vw;
}

#testid {
  background-color: red;
}

.nav-hamburger-container {
  height: calc(max(min(36px, 3vw), 16px) + 2vw);
}

@media (max-width: 1458px) {
  .nav-hamburger-container {
    grid-column: 2 / -1;
  }
}

.nav-hamburger-menu {
  height: 100%;
  width: 1.37rem;
  margin-right: 1vw;
}

@media (min-width: 1459px) {
  .nav-links {
    display: relative;
    position: relative;
  }
  .nav-hamburger-container {
    display: none;
    position: absolute;
  }
}

@media (max-width: 1458px) {
  .nav-links {
    visibility: hidden;
    position: absolute;
  }
  .nav-hamburger-container {
    visibility: visible;
    position: relative;
    grid-column: 24/-1;
  }
}

.nav-title {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.navbar a {
  position: relative;
  text-decoration: none;
  font-size: 0.7rem;
}

.navbar a:hover {
  position: relative;
  color: #83B9F7;
}

.footer {
  text-decoration: none;
  font-size: 0.8rem;
  border-top: 1px solid #f2f2f2; 

  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  opacity: 90%;
  text-align: center;
  padding: 10px 0;
}
.footer:before {
  content:"Click here to send us your questions or comments.";
}

.footer:hover {
  color: #83B9F7;
}

.speaker-list-short {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 0.5vw;
}

.speakers-container {
  margin-left: 3.44418599285vw;
  margin-right: 3.44418599285vw;
}

.blog-preview {
  flex: 0 0 12.5%;
  margin-right: 10px;
}

.blog-preview:last-of-type {
  margin-right: 0;
}

.carousel-container {
  width: 88.5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  justify-content: flex-start;
}

.carousel-btn-container {
  display: flex;
  width: 10vw;
}

.carousel-btn {
  margin-top: 0.7vw;
  box-sizing: border-box;
  height: 10vw;
  width: 45%;
}

.down-carrot {
  font-size: small;
}

.banner {
  background-image: url('./images/Nephi_banner.jpg');
  background-size: cover;
  height: min(45vw, 92vh);
}

.main-banner-text {
  position: absolute;
  z-index: 10;
}

@media (min-width: 1420px) {
  #main-banner-links {
    padding-top: 20vw;
    width: fit-content;
  }
}

@media (min-width: 1285px) and (max-width: 1420px) {
  #main-banner-links {
    padding-top: calc(20vw + 1rem);
    width: fit-content;
  }
}

@media (min-width: 527px) and (max-width: 1285px) {
  #main-banner-links {
    padding-top: calc(20vw + 2rem);
    width: 30vw;
  }
}

@media (min-width: 0px) and (max-width: 527px) {
  #main-banner-links {
    display: none;
    width: 30vw;
  }
}

@media (min-width: 0px) and (max-width: 527px) {
  #backup-banner-links {
    display: block;
    padding-top:30vw;
    width: 30vw;
  }
}

@media (min-width: 527px) {
  #backup-banner-links {
    padding-top:10em;
    display: none;
  }
}

.first-element-spacing {
  margin-top: 1.31556174964vw;
}

.main-text {
  margin-left: 5.57280900008vw;
  margin-right: 5.57280900008vw;
}

.main-banner-link {
  padding-top: 0.25vw;
  padding-bottom:0.25vw;
  font-size: 0.8rem;
  text-shadow: 1vw;
  color:#336bb4;
}

.main-banner-link:hover{
  text-decoration:underline;
  transition: text-decoration 500ms;
  color: #193559;
  transition: color linear 500ms;
}


@media (min-width: 1330px) {
  .main-banner-links {
    display:block;
  }
}

@media (max-width: 1330px) {
  .main-banner-links {
    display: block ;
  }
}

p.main-banner-text {
  padding-top: 2.1286vw;
}

@media (min-width: 1560px) {
  p.main-banner-text {
    padding-right: 35vw;
  }
}
@media (min-width: 1410px) and (max-width: 1560px) {
  p.main-banner-text {
    padding-right: 37vw;
  }
}
@media (min-width: 1352px) and (max-width: 1410px) {
  p.main-banner-text {
    padding-right: 41vw;
  }
}
@media (min-width: 1228px) and (max-width: 1352px) {
  p.main-banner-text {
    padding-right: 43vw;
  }
}
@media (min-width: 479px) and (max-width: 1228px) {
  p.main-banner-text {
    padding-right: 49vw;
  }
}

@media (min-width: 479px) {
  p.main-banner-text {
    display:block;
  }
  p.backup-banner-text {
    display: none;
  }
}

@media (max-width: 479px) {
  p.main-banner-text {
    display: none;
  }
  p.backup-banner-text {
    display: block;
    margin-right: 50vw;
    margin-top: 17vw;
  }
}

@media (max-width: 436px) {
  p.backup-banner-text {
    margin-right: 55vw;
  }
}

@media (max-width: 422px) {
  p.backup-banner-text {
    margin-right: 60vw;
  }
}

@media (max-width: 380px) {
  p.backup-banner-text {
    display: none;
  }
}

@media (max-width: 479px) {
  #now-hear-our-voice {
    padding-top: calc(3vw + 1rem);
  }
}

.speaker-description {
  white-space: pre-line;
}

.speaker-details {
  margin-right: 3.44418599285vw;
  margin-left: 3.44418599285vw;
}

.speaker-detail-img {
  height: 100px;
  background-size: cover;
  display: block;
  border-radius: 50%;
  margin-top: 1.67342069595vw;
  margin-bottom: 1.03423086758vw;
}

.speaker-list-all {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 3.44418599285vw;
  margin-right: 3.44418599285vw;
}

.speaker-list-all-card {
  outline-color:#333;
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-left: 1.75vw;
  padding-right: 1.75vw;
  text-decoration: none;
}

.speaker-list-all-card:hover{
  text-decoration:underline;
  transition: text-decoration 500ms;
  color: #193559;
  transition: color linear 500ms;
  outline: solid;
  transition: outline linear 500ms;
  outline-color: #193559;
  transition: outline-color linear 500ms;
}

@media (max-width: 650px) {
  .speaker-list-all-card {
    width: 26.4vw;

  }
}

@media (min-width: 650px) and (max-width: 1100px) {
  .speaker-list-all-card {
    width: 19.1vw;
  }
}

@media (min-width: 1100px) and (max-width: 1400px) {
  .speaker-list-all-card {
    width: 14.8vw;
  }
}

@media (min-width: 1400px) {
  .speaker-list-all-card {
    width: 11.8vw;
  }
}

.speaker-list-all h4 {
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}

.speaker-carousel-label {
  width: 100%;
  white-space: normal;
  word-wrap: break-word;
  font-size: 0.75rem;
}

.speaker-name-wordcount h4:last-of-type {
  margin-left: auto;
}

.speakerall-img {
  width: 100%;
}

.speaker-detail-outer-img {
  background-size: cover;
  display: block;
  border-radius: 50%;
  width: 10vw;
  margin-right: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.content-toolbox {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.toolbox-item {
  width: 100%;
  height: 85%;
  padding-top: 0.5vw;
}

.toolbox-item-label {
  padding-bottom: 1vw;
}

@media (min-width: 1400px) {
  .toolbox-container {
    width: 21%;
    height: auto;
  }
}
@media (max-width: 1400px) and (min-width: 1124px) {
  .toolbox-container {
    width: 29%;
    height: auto;
  }
}
@media (max-width: 1124px) {
  .toolbox-container {
    width: 45%;
    height: auto;
  }
}

.toolbox-container {
  margin-bottom: 5vw;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  border: solid 1px black;
  padding: 1vw;
}

.toolbox-item img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  object-position: right top;
}

.navbar-expanded-menu {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  column-count: 2;
  flex-direction: column;
  padding: 0.5vw;
  z-index: 1;
  background-color: white;
  border: 1px solid black;
  width: 11vw;
}

.nav-expansion {
  position: relative;
  width: 14vw;
  padding: 0.35vw;
  margin: 0px;
}


.collapsible-content {
  background-color: #ededed;
  padding: .5em

  /* border:groove 10px gray;
  padding: 1em;
  border-radius: 1%; */
}

.collapsible-header {
  background-color: #dddddd;
  cursor: pointer;
}

.collapsible-header:hover{
  text-decoration:underline;
  transition: text-decoration 500ms;
  background-color: #cbcbcb;
  transition: background-color linear 500ms;
}

.collapsible-arrow{
  float: right;
  padding-right: 0.3em;
}

.cameo-list-all {
  display:flex;
  padding-top: 5vw;
  padding-left: 5vw;
  padding-right:5vw;
  flex-wrap: wrap;

  justify-content: center;
}

.cameo-list-all-card {
  display:flex;
  height: fit-content;
  width: 20vw;

  text-decoration: none;
  text-align:center;
  justify-content: center;

  outline-style:double;
  outline-width:4px;
  outline-color:gray; 

  padding: .7em;
  margin-left: 2vw;
}

.cameo-list-all-card:hover{
  text-decoration:underline;
  transition: text-decoration 500ms;
  color: #193559;
  transition: color linear 500ms;
  outline-color: #193559;
  transition: outline-color linear 500ms;
}

.concept-list-all {
  display:flex;
  flex-wrap: wrap;
  align-items: center;
  width: 90vw;

  padding-top: 5vw;
  padding-left: 5vw;
  padding-right:5vw;
}

.concept-list-all-card {
  display:flex;
  width:fit-content;
  height: fit-content;

  text-decoration: none;
  text-align:center;

  outline-style:double;
  outline-width:4px;
  outline-color:gray; 

  padding: 1.5em;
  outline-offset: -2vw;
}

.concept-list-all-card:hover{
  text-decoration:underline;
  transition: text-decoration 500ms;
  color: #193559;
  transition: color linear 500ms;
  outline-color: #193559;
  transition: outline-color linear 500ms;
}


#home-side-image {
  object-fit: cover;
}

.copyright {
  padding:10px;
  border-top: solid lightgray;
  color:gray;
  text-align:right;
  vertical-align:middle;
  justify-self: end;
  font-size: 0.55rem;
}